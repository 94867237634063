@import url('https://fonts.googleapis.com/css2?family=Mitr:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@400;500;600;700;800&display=swap');

@fontface {
  font-family: 'Prompt', cursive;
  src: url('https://fonts.googleapis.com/css2?family=Prompt')
}

@font-face {
  font-family: 'supermarket';
  src: local('supermarket'), url(./fonts/supermarket.ttf) format('truetype');
}

@font-face {
  font-family: 'ThaiSansNeue-Regular';
  src: local('ThaiSansNeue-Regular'), url(./fonts/ThaiSansNeue-Regular.ttf) format('truetype');
}

body {	
  margin: 0;
  font-family: 'Sarabun','supermarket','Kanit','Prompt','Mitr',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

