
/*My*/
/*
@media (min-width: 768px)
.container {
    max-width: 720px;
}
@media (min-width: 576px)
.container {
    max-width: 540px;
} 


*/
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.navBarStyle {
    padding: 12px;
	-webkit-box-shadow: 0 8px 6px -6px #999;
    -moz-box-shadow: 0 8px 6px -6px #999;
    box-shadow: 0 8px 6px -6px #999;

}
.leftTopBar {
	justify-content: center;
	margin-top: 20px;
	margin-bottom:32px;
}

/* 
@media only screen and (min-width: 375px) and (max-width: 969px) {  
  .navBarStyle { 
	padding: 14px;
	box-shadow: 0 1px 6px -2px #999;

  }
  .center-mobile {
	display: flex;
	justify-content: center;
	text-align: center;  
  }
  .leftTopBar {
	display: flex;
	justify-content: center;
	text-align: center;  
  }

}
*/

.footerBar {
  position: fixed;
  bottom: 0;
  left:0;
  width: 100%;
  padding-left:12px;
  padding-right:12px;
  display: flex;
  justify-content: center;
  text-align: center;
  overflow: hidden !important;
  background-color: white;
}

.priceBar {
  width: 100%;
  display: flex;
  overflow: hidden !important;
}
.headerBar {
  background-color:#ffffff;
  padding: 10px;
  font-style: bold;
  font-size: 18px;  
}
.tableWithBorder {
  border: 1px solid #eeeeee;
}
.tableMiddleBorder {
  border-right: 3px solid #eeeeee;
}
.buyButton {
  background-color: #EE4D2D  !important; 
  border: none !important; 
  margin-top:0px;
  margin-bottom:0px;
  margin-left:0px;
  margin-right:0px;
  width: 100%;  
  padding: 6px;
  display: flex;
  justify-content: center;
  color: white;
  font-size:18px !important; 
  font-weight :600 !important;   
  font-family: 'Prompt' !important; 
  cursor: pointer;
  -webkit-border-radius: 30px !important; 
  -moz-border-radius: 30px !important; 
  border-radius: 30px !important; 
  text-align: center;
  text-decoration: none;
}
.confirmButton {
  background-color: #EE4D2D  !important; 
  border: none !important; 
  width: 100%;  
  display: flex;
  justify-content: center;
  color: white;
  font-size: 18px !important; 
  font-weight :400 !important;   
  font-family: 'Prompt' !important; 
  cursor: pointer;
  -webkit-border-radius: 10px !important; 
  -moz-border-radius: 10px !important; 
  border-radius: 10px !important; 
  text-align: center;
  text-decoration: none;
}


.discountText{
	background-color: #ffffff  !important; 
	border-style: solid;
	border-color: red;
	border-radius: 8px !important; 
	font-size: 16px !important; 
	font-weight: 600;
	color:red;
	padding-right:10px !important; 	
	padding-left:10px !important; 
	margin-right:10px;
	margin-top:10px;
	margin-bottom:0px;
	float: right;	
}
.priceText{
	color:#EE4D2D   !important; 
	font-weight: 700   !important; 
	font-size: 32px   !important; 
	font-family: 'Prompt'   !important; 
	text-align:'left';
	padding-right:0px;
	padding-left:10px;
	float: left;
}
.oriPriceText{
	color:#bbbbbb   !important; 
	font-weight: 400   !important; 
	font-size: 20px   !important; 
	font-family: 'Prompt'   !important; 
	text-align:'left';
	padding-right:6px;
	padding-left:10px;
	padding-top:10px;
	float: left;
	text-decoration: line-through;	
}

.deliveryText{
	background-color: #71dec6  !important;
	border-radius: 8px !important; 
	font-size: 16px !important; 
	color:white;
	padding-right:10px;	
	padding-left:10px;
	padding-top:2px;
	padding-bottom:2px;
	margin-right: 8px;
	font-family: 'Prompt';
}
.biggerText{
	color:black   !important; 
	font-weight: 400   !important; 
	font-size: 24px   !important; 
	text-align:'left';
	font-family: 'Sarabun';
}
.bigText{
	color:black   !important; 
	font-weight: 400   !important; 
	font-size: 18px   !important; 
	text-align:'left';
	font-family: 'Sarabun';
}
.normalText{
	color:black   !important; 
	font-weight: 400   !important; 
	font-size: 16px   !important; 
	text-align:'left';
	font-family: 'Sarabun';
}
.smallText{
	font-weight: 400   !important; 
	font-size: 14px   !important; 
	text-align:'left';
	font-family: 'Sarabun';
}
.smallerText{
	color:black   !important; 
	font-weight: 400   !important; 
	font-size: 12px   !important; 
	text-align:'left';
	font-family: 'Sarabun';
}
.iconSmallNoMargin{  
  font-size: 24px;
  margin-right: 0px;
}
.iconSmall{  
  font-size: 24px;
  margin-right: 6px;
}
.iconBig{  
  font-size: 60px;
  margin-right: 6px;
  color: #cccccc;
}
.iconImage{  
  height:50px;
  width: 48px;
}
.iconNav{  
  font-size: 24px;
  margin-left: 16px;
  margin-right: 16px;
  margin-top:  10px;
}
.linkGrey {
	color: #666666;
	text-decoration: underline;
}
.vertical-center {
	margin-top: 6px;
	top: 50%;
	left: 0;
	-ms-transform: translate(0%, 0%);
	transform: translate(0%, 0%);
}

.imageLoading {
	width:100%;
	height: 150px;
	display: flex;
	justify-content: center;
	margin-top: 70px;
	font-family: 'Prompt';
	font-size: 14px   !important; 
}

.spaceBar {
  width: 100%;
  display: flex;
  background-color: #eeeeee;
  height:2px;
}

.promoBar {
  width: 100%;  
  padding: 6px;
  font-size: 16px;
  font-family: 'Sarabun';  
  border: none !important; 
  -webkit-border-radius: 10px !important; 
  -moz-border-radius: 10px !important; 
  border-radius: 10px !important; 
}

.paymentOptionBar {
  margin-top:20px;
  margin-bottom:20px;
  background-color: 'red';
  font-size: 16px;
  font-family: 'Sarabun';  
  border: 0px solid black !important; 
  -webkit-border-radius: 10px !important; 
  -moz-border-radius: 10px !important; 
  border-radius: 10px !important; 
}

.headerBarCenter {
  width: 100%;
  background-color:#ffffff;
  padding: 10px;
  font-style: bold;
  font-size: 18px;  
  display: flex;  
  justify-content: center;
}
.alignRight {
  display: flex;  
  justify-content: right;
}
.alignCenter {
  display: flex;  
  justify-content: center;
}
.alignLeft {
  display: flex;  
  justify-content: left;
}
.alignRight {	
  display: flex;  
  justify-content: flex-end;
}

.dropdownInput {
  margin-bottom: 16px;
  color:#bbbbbb;
}

.barCenter {
  width: 100%;
  background-color:#ffffff;
  display: flex;  
  justify-content: center;
  text-align: center;
}
.barCenter80 {
  margin-left:30px;
  margin-right:30px;
  background-color:ffffff;
  display: flex;  
  justify-content: center;
  text-align: center;
}

.footerBar {
  margin-top:0px;
  margin-bottom:0px;
  width: 100%;  
  display: flex;
  justify-content: center;
}
.roundBox {
  border: none !important; 
  width: 100%;  
  height: auto;
  margin: 16px;
  padding: 16px;
  display: flex;
  justify-content: center;
  color: black;
  font-size: 16px !important;
  font-family: 'Prompt' !important; 
  cursor: pointer;
  -webkit-border-radius: 20px !important; 
  -moz-border-radius: 20px !important; 
  border-radius: 20px !important; 
  text-align: center;
  text-decoration: none;
}
.cancelButton {
  background-color: #ffffff  !important; 
  border: 1px solid black !important; 
  width: 100%;  
  display: flex;
  justify-content: center;
  color: black;
  font-size: 18px !important; 
  font-weight :400 !important;   
  font-family: 'Prompt' !important; 
  cursor: pointer;
  -webkit-border-radius: 10px !important; 
  -moz-border-radius: 10px !important; 
  border-radius: 10px !important; 
  text-align: center;
  text-decoration: none;
}

.cancelButton:hover {
   color: #000000  !important; 
}
.loginButton {
  background-color: #000000  !important; 
  border: none !important; 
  width: 100%;  
  display: flex;
  justify-content: center;
  color: white;
  font-size: 18px !important; 
  font-weight :400 !important;   
  font-family: 'Prompt' !important; 
  cursor: pointer;
  -webkit-border-radius: 10px !important; 
  -moz-border-radius: 10px !important; 
  border-radius: 10px !important; 
  text-align: center;
  text-decoration: none;
}
.bigButton {
  background-color: #ffffff  !important; 
  border: 3px solid black  !important; 
  width: 100%;  
  height: 80px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  text-decoration: none;
  color: #000000 !important;
  font-size: 18px !important; 
  font-weight :400 !important;   
  font-family: 'Prompt' !important; 
  cursor: pointer;
  -webkit-border-radius: 10px !important; 
  -moz-border-radius: 10px !important; 
  border-radius: 10px !important; 
}
.blackButton {
  background-color: #000000  !important; 
  border: none !important;
  width:60%;
  color: white;
  font-size: 18px !important; 
  font-weight :400 !important;   
  font-family: 'Prompt' !important; 
  cursor: pointer;
  -webkit-border-radius: 10px !important; 
  -moz-border-radius: 10px !important; 
  border-radius: 10px !important; 
  text-decoration: none;
}
.greyButton {
  background-color: #cccccc  !important; 
  border: none !important; 
  width: auto;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 18px !important; 
  font-weight :400 !important;   
  font-family: 'Prompt' !important; 
  cursor: pointer;
  -webkit-border-radius: 10px !important; 
  -moz-border-radius: 10px !important; 
  border-radius: 10px !important; 
  text-align: center;
  text-decoration: none;
}
.linkMinimal {
	color: #344d8c;
	text-decoration: normal;
}

.disable-link {
  pointer-events: none;
}
.enable-link {
  pointer-events: auto;
}

.fullWidth {
	width: 100%;
}


/* Loading*/

/* Style the modal */
.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal Content */
.modal-content {
  margin: 30px;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 5px;
  display: flex;
  align-items: center;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #fefefe;
}

.modal-text {
  margin-left: 14px;
  font-size: 24px;
}

/* Create the circle loader with pure CSS */
.loader {
  width: 50px;
  height: 50px;
  border: 8px solid #ddd;
  border-top: 8px solid #2196f3;
  border-bottom: 8px solid #2196f3;
  border-radius: 50%;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.modal-content-small {
  margin: 30px;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 5px;
  display: flex;
  align-items: center;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #fefefe;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.right {
    display: flex;
    justify-content: right;
    align-items: right;
}

.iconBox {
  background-color: #dddddd  !important; 
  border: none !important; 
  width: 80px !important;
  height: 80px !important;
  margin: 8px !important;
  padding: 8px !important;
  display: flex;
  justify-content: center;
  color: black;
  cursor: pointer;
  -webkit-border-radius: 20px !important; 
  -moz-border-radius: 20px !important; 
  border-radius: 20px !important; 
  text-align: center;
  text-decoration: none;
}

.contentBox {
  border: 1px solid #cccccc;
  width: 90%;
  padding: 24px;
  display: block;
  color: black;
  cursor: pointer;
  -webkit-border-radius: 12px !important; 
  -moz-border-radius: 212px !important; 
  border-radius: 12px !important;
}

.roundBox {
  border: none !important; 
  display: flex;
  justify-content: center;
  color: black;
  cursor: pointer;
  -webkit-border-radius: 20px !important; 
  -moz-border-radius: 20px !important; 
  border-radius: 20px !important;
  box-shadow: 2px 3px #888888;
  text-align: center;
  text-decoration: none;
}

.roundBoxButton {
  border: none !important; 
  display: flex;
  justify-content: center;
  color: black;
  background-color: #cccccc  !important; 
  font-size: 18px;
  padding-top: 16px;
  padding-bottom: 8px;
  margin-bottom: 6px;
  cursor: pointer;
  -webkit-border-radius: 20px !important; 
  -moz-border-radius: 20px !important; 
  border-radius: 20px !important;
  box-shadow: 2px 3px #888888;
  text-align: center;
  text-decoration: none;
  
}

/* quill */
.ql-align-right {
	text-align: right;
	justify-content: right;
    align-items: right;
}
.ql-align-center {
	text-align: center;
	justify-content: center;
    align-items: center;
}
.ql-align-left {
	text-align: left;
	justify-content: left;
    align-items: left;
}

.ql-editor{
  min-height: 300px !important;
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: scroll;
}

.wrapword {
    white-space: -moz-pre-wrap !important;  /* Mozilla, since 1999 */
    white-space: -webkit-pre-wrap;          /* Chrome & Safari */ 
    white-space: -pre-wrap;                 /* Opera 4-6 */
    white-space: -o-pre-wrap;               /* Opera 7 */
    white-space: pre-wrap;                  /* CSS3 */
    word-wrap: break-word;                  /* Internet Explorer 5.5+ */
    word-break: break-all;
    white-space: normal;
}

.table-border {
  border-bottom: 2px solid #dddddd !important; 
  text-decoration: none;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size:14px;
}
.cell-border {
  border: 0px solid #000000 !important; 
  text-decoration: none;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-size:14px;
  word-wrap: break-word;
  display: inline-block;
  
}
.cell-smallmargin {
  border: 0px solid #000000 !important; 
  text-decoration: none;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-size:14px;
  word-wrap: break-word;
  display: inline-block;
}

.cell-border-bottom {
  border: 0px solid #000000 !important; 
  text-decoration: none;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-size:14px;
  word-wrap: break-word;
  align-items: flex-end;
  display: flex;
}


@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.card-center {
    display: flex;
    justify-content: center;
    align-items: center;
	width: 90%;
	margin-bottom: 32px;
}

.card-center {
    display: flex;
    justify-content: center;
    align-items: center;
	width: 90%;
	margin-bottom: 32px;
	padding:20px !important;
}

.modal-backdrop {
    z-index: 100000 !important;
}

.modal {
    z-index: 100001 !important;
}

.sidemenulink {
	padding-bottom: 16px;
}

a.sidemenulink:link { color:#898a8a; text-decoration:none; font-weight:normal; }
a.sidemenulink:visited { color: #898a8a; text-decoration:none; font-weight:normal; }
a.sidemenulink:hover { color: #333333; text-decoration:none; font-weight:600; }
a.sidemenulink:active { color: #898a8a; text-decoration:none; font-weight:normal; }

/*@media only screen and (max-width: 970px) {*/
/* iPad 1, 2, Mini and Air */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {

}

/* iPad 3, 4 and Pro 9.7" */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2) {
  
}


@media only screen and (max-width: 970px) {
  .navBarStyle { 
	padding: 12px;
	box-shadow: 0 1px 6px -2px #999;
  }
  .center-mobile {
	display: flex;
	justify-content: center;
	text-align: center;  
  }
  .leftTopBar {
	display: flex;
	justify-content: center;
	text-align: center;  
  }
  .card-center {
    display: flex;
    justify-content: center;
    align-items: center;
	width: 95%;
	margin-bottom: 32px;	
	padding:8px !important;
  }

}
@media only screen and (max-width: 500px) {
  .iconNav{  
	  font-size: 24px;
	  margin-left: 8px;
	  margin-right: 8px;
	  margin-top:  10px;
   }
}
@media only screen and (max-width: 300px) {
  .iconNav{  
	  font-size: 24px;
	  margin-left: 4px;
	  margin-right: 4px;
	  margin-top:  10px;
   }
}


/*SideBar*/
/*
.sidebar {
  margin: 0;
  padding: 0;
  width: 200px;
  background-color: #f1f1f1;
  position: fixed;
  height: 100%;
  overflow: auto;
}

.sidebar a {
  display: block;
  color: black;
  padding: 16px;
  text-decoration: none;
}

.sidebar a.active {
  background-color: #04AA6D;
  color: white;
}

.sidebar a:hover:not(.active) {
  background-color: #555;
  color: white;
}

div.content {
  margin-left: 200px;
  padding: 1px 16px;
  height: 1000px;
}

@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
  .sidebar a {float: left;}
  div.content {margin-left: 0;}
}

@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
  }
}*/
/**/

/* end Loading */
/*

.responsive {
  width: 100%;
  max-width: 25px;
  height: auto;
}
.iconImage{  
  display: block;
  height:50px;
  width: 48px;	
}

.lineImage{
	width: 20px;
	display: flex;
	justify-content: center;
}

.buyButtonFull {
  background-color: #EE4D2D  !important; 
  border: none !important; 
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  display: flex;
  justify-content: center;
  color: white;
  font-size: 20px !important; 
  cursor: pointer;
  -webkit-border-radius: 30px !important; 
  -moz-border-radius: 30px !important; 
  border-radius: 30px !important; 
  text-align: center;
  text-decoration: none;
}

.contactColumn {
  background-color: #cccccc   !important; 
  border: none !important; 
  position: fixed;
  bottom: 0;
  left:0;
  width: 20%;
  display: flex;
  color: white;
}
.footer {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: #CCCCCC; 
  overflow: hidden !important;
}

.contactButton1 {
  background-color: #ffffff   !important; 
  border: none !important; 
  position: fixed;
  bottom: 0;
  left:0;
  margin-top:0px;
  margin-left:12px;
  margin-right:12px;
  margin-bottom:10px;
  width: 20%;
  display: flex;
  justify-content: center;
  color: white;
  font-size: 40px !important; 
  cursor: pointer;
  -webkit-border-radius: 30px !important; 
  -moz-border-radius: 30px !important; 
  border-radius: 30px !important; 
  text-align: center;
  text-decoration: none;
}

.contactButton {
  background-color: #ffffff   !important; 
  border: none !important; 
  position: fixed;
  bottom: 0;
  left:0;
  margin-top:0px;
  margin-left:12px;
  margin-right:12px;
  margin-bottom:10px;
  width: 20%;
  display: flex;
  color: white;
  font-size: 20px !important; 
  cursor: pointer;
  text-align: center;
}

.buyButton {
  background-color: #EE4D2D  !important; 
  border: none !important; 
  position: fixed;
  bottom: 0;
  right:0;
  margin-top:12px;
  margin-left:12px;
  margin-right:14px;
  margin-bottom:12px;
  width: 70%;
  display: flex;
  justify-content: center;
  color: white;
  font-size: 20px !important; 
  cursor: pointer;
  -webkit-border-radius: 30px !important; 
  -moz-border-radius: 30px !important; 
  border-radius: 30px !important; 
  text-align: center;
  text-decoration: none;
}
*/

/*.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}*/